import React from 'react';

import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';

import SEO from '../components/seo';
import { parseLocale } from '../locale';
import { mapQueryToDynamicDonateToOurPartnersProps } from '../data-mappers/donate-to-our-partners.mapper';
import { DonateToOurPartnersCategoryTemplate } from '../templates/donate-to-our-partners.template';
import { DonateToOurPartnersByCategoryQuery } from '../../graphql-types';
import { useBreadcrumbs } from '../hooks/useBreadcrumbs';

type DonateToOurPartnerByCategoryProps = {
  data: DonateToOurPartnersByCategoryQuery;
  pageContext: {
    categoryId: string;
  };
};

const DonatePartnerByCategoryPage: React.FC<DonateToOurPartnerByCategoryProps> = ({ data, pageContext }) => {
  const { cms } = data;

  const intl = useIntl();
  const locale = parseLocale(intl.locale);

  const props = mapQueryToDynamicDonateToOurPartnersProps(cms, locale, pageContext.categoryId);

  useBreadcrumbs({
    pageTitle: cms.donateToOurPartner?.title ?? '',
    contentCategory: intl.formatMessage({ id: 'kering_foundation' }),
  });

  return (
    <>
      <SEO
        lang={locale}
        title={cms.donateToOurPartner?.title ?? ''}
        description={cms.donateToOurPartner?.metas?.description ?? ''}
      />
      <DonateToOurPartnersCategoryTemplate {...props} />
    </>
  );
};

export default DonatePartnerByCategoryPage;

export const query = graphql`
  query DonateToOurPartnersByCategory($locale: String, $categoryId: ID!) {
    cms {
      donateToOurPartner(locale: $locale) {
        title
        header {
          ...DonatePartnersHeader
        }
        metas {
          description
        }
      }

      category(id: $categoryId) {
        id
        programs(sort: "title:asc") {
          ...Program
          donation_page_thumbnail {
            ...ProgramThumbnail
          }
        }
      }

      categories(sort: "created_at:desc", locale: $locale, where: { display_on_donate_page: true }) {
        programCount
        ...ProgramCategoryWithProgramId
      }
    }
  }
`;
